import React, {Fragment} from 'react';
import Meta from '@components/Meta';

const Search = (): JSX.Element => (
  <Fragment>
    <Meta title="Search" description="2U Search" />
    <div>
      <h1>Hi people</h1>
      <p>Welcome to your new Gatsby site.</p>
      <p>Now go build something great.</p>
    </div>
  </Fragment>
);

export default Search;
